import React from 'react'
import profile from '../asset/profile.jpg'
import logodks from '../asset/LSP.png'
import logobnsp from '../asset/logo-bnsp.png'
// import { faHouse } from '@fortawesome/free-brands-svg-icons'
import { faHouse, faSimCard, faCalendar, faCircleInfo, faLightbulb, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function HeaderMobile() {

    // Mobile navbar

  return (
            <>
                <div className="navbar fixed z-40 bg-base-100">
                    <div className="flex-none">
                        <div className='dropdown'>
                            <button tabIndex={0} role='button' className="btn btn-square btn-ghost">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                            </svg>
                            </button>
                            <ul tabIndex={0} className="menu menu-md dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-96 uppercase">
                                <li>
                                    <div className='flex items-center'>
                                        <FontAwesomeIcon className='h-5' icon={faHouse} />
                                        <a className='font-bold ps-1'>Beranda</a>
                                    </div>
                                </li>
                                <li>
                                    <div className='flex items-center'>
                                        <FontAwesomeIcon className='h-5 ps-1' icon={faSimCard} />
                                        <a className='font-bold ps-2'>Layanan</a>
                                    </div>
                                </li>
                                <li>
                                    <div className='flex items-center'>
                                        <FontAwesomeIcon className='h-5 ps-1' icon={faCalendar} />
                                        <a className='font-bold ps-1'>Jadwal</a>
                                    </div>
                                </li>
                                <li>
                                    <div className='flex items-center'>
                                        <FontAwesomeIcon className='h-5 ps-1' icon={faCalendar} />
                                        <a className='font-bold ps-1'>Whatsapp</a>
                                    </div>
                                </li>
                                <li>
                                    <div className='flex items-center'>
                                        <FontAwesomeIcon className='h-5 ps-1' icon={faCircleInfo} />
                                        <a className='font-bold ps-0'>About us</a>
                                    </div>
                                </li>
                                <li className='border-b-2 pb-3 -mx-2 ps-2'>
                                    <div className='flex items-center'>
                                        <FontAwesomeIcon className='h-5 ps-[0.4rem]' icon={faLightbulb} />
                                        <a className='font-bold ps-0'>Support</a>
                                    </div>
                                </li>
                                <li className='mt-3 ms-3'>
                                    <div className='btn rounded-full flex justify-between items-center w-32 py-3 bg-black'>
                                        <FontAwesomeIcon className='h-4 ps-[0.4rem] text-white' icon={faPlus} />
                                        <a className='font-bold text-white ps-0'>Daftar</a>
                                    </div>
                                </li>
                                <li className='mt-5 ms-3'>
                                    <div className='py-3'>
                                        <div className='flex justify-between items-start '>
                                            <img className='w-40' src={logodks} alt="" />
                                            <img className='w-40' src={logobnsp} alt="" />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="flex-1">
                    </div>
                    <div className="flex-none hidden">
                        <button className="btn btn-square btn-ghost">
                        <img className="inline-block w-8 h-8 rounded-full bg-cover stroke-current" src={profile}></img>
                        </button>
                    </div>
                </div>
            </>
     )
}

export default HeaderMobile